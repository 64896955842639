<template>
    <div id="app" class="w-screen h-screen flex items-center justify-center">
        <div class="flex flex-col sm:flex-row items-center justify-center container-xl space-x-0 sm:space-x-12 space-y-10 sm:space-y-0">

            <div class="image-holder">
              <img alt="" class="block rounded-full border-4 border-white self-start"
                   src="./assets/foto-bram-profiel-2024.jpeg" style="max-width: 180px;">
            </div>

            <section class="flex flex-col space-y-3 self-start sm:self-auto">
                <h1 class="text-2xl sm:text-3xl uppercase text-white font-extrabold">Bram van Rijswijk</h1>
                <section>
                    <p class="text-sm sm:text-base text-white font-medium tracking-wider">Senior Software Engineer @ <a
                        class="font-extrabold underline" href="https://ordina.nl" target="_blank">Ordina</a></p>
                    <p class="text-sm sm:text-base text-white font-medium tracking-wider">Founder <a
                        class="font-extrabold underline" href="https://watisonsbudget.nl" target="_blank">Watisonsbudget</a> & <a
                      class="font-extrabold underline" href="https://workslider.com" target="_blank">Workslider</a>
                    </p>
                </section>
            </section>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.image-holder img {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% !important;
}

#app {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
</style>
